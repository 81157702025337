import { DestinationTypeEnum } from "@/static/contants";
import { aggregateLocationByType, normalizeLocation } from "@/utils/locationNormalizers";
import { useCallback } from "react";
import useApi from "./useApi";

export default function useUserLocation(checkLocation = false) {
    const { withLocation, withNormalizedLocation } = useApi();

    const getLocationType = useCallback(({ city, state, country }) => {
        let destinationType;
        if (country) {
            destinationType = DestinationTypeEnum.COUNTRY;
        }
        if (state) {
            destinationType = DestinationTypeEnum.STATE;
        }
        if (city) {
            destinationType = DestinationTypeEnum.CITY;
        }
        return destinationType;
    }, []);

    const getName = withNormalizedLocation(({ city, state, country }) => (city || state || country));

    const getAddress = withNormalizedLocation(({ city, state, country }) => {
        console.log(`getAddress ==================> city: ${city}, state: ${state}, country: ${country}`);

        return aggregateLocationByType({
            type: getLocationType({ city, state, country }),
            action: normalizeLocation
        })(`${city}, ${state}, ${country}`);
    });

    /**
     * locationObject: { city, state, country }
     */
    const getUserLocation = withLocation(locationObject => {
        console.log(`getUserLocation ==================> locationObject: ${locationObject}`);

        const userLocation = {
            ...locationObject,
            type: getLocationType(locationObject),
            address: getAddress(locationObject),
            name: getName(locationObject)
        };

        console.log(`getUserLocation ==================> userLocation: ${userLocation}`);

        return userLocation;
    }, checkLocation);

    return {
        getUserLocation,
        getLocationType,
        getAddress,
        getName,
    };
}
