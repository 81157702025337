import { getGuestUnit, guestAgeTypes } from '@/components/formComponents/GuestSelector/guestSelectorHelpers';
import SearchIntendTypes from '@/components/map/enums/SearchIntendTypes';
import StyleTypes from '@/components/map/enums/StyleTypes';
import General from '@/components/map/helpers/General';
import {
    DefaultPriceRanges,
    filterTypes,
    LocationTypes,
    propertyVariant,
    searchFiltersState,
    sortByTypes,
    viewMode,
} from '@/containers/searchResults/enum';
import {
    convertToHash,
    getListingId,
    getSearchIntent,
    getSearchQueryParams,
    getValidLocationType,
    priceCurrencyConverter,
} from '@/containers/searchResults/helpers';
import { useBudget, useDates, useGetGuests } from '@/containers/searchResults/hooks';
import { constants } from '@/src/utils/constants';
import createReducer from '@/src/utils/createReducer';
import { guestsValidator } from '@/src/utils/globalValidations';
import { compareIds, concatFilter, convertToNum, getValidated } from '@/utils/globalFunc';
import { aggregateLocationByType, normalizeLocation, normalizeLocationString, splitByToken } from '@/utils/locationNormalizers';

import {
    ARE_SEARCH_FILTERS_VALID,
    SEARCH_RESULTS_AMENITIES_CONTAINER_OFFSET_HEIGHT_CHANGE,
    SEARCH_RESULTS_BOUNDS_SEARCH_TOGGLE,
    SEARCH_RESULTS_CHANGE_SEARCH_FILTERS_STATE,
    SEARCH_RESULTS_CHANGE_SEARCH_INTEND,
    SEARCH_RESULTS_DATA_AVAILABLE,
    SEARCH_RESULTS_DATA_ERROR,
    SEARCH_RESULTS_DATA_LOADING,
    SEARCH_RESULTS_FILTERS_REQUEST_DATA_LOADING,
    SEARCH_RESULTS_DATEPICKER_CHANGE,
    SEARCH_RESULTS_DATEPICKER_FOCUS_CHANGE,
    SEARCH_RESULTS_FILTERS_AMENITIES,
    SEARCH_RESULTS_FILTERS_ATTRACTIONS,
    SEARCH_RESULTS_FILTERS_BATHROOMS_COUNT,
    SEARCH_RESULTS_FILTERS_BEDROOMS_COUNT,
    SEARCH_RESULTS_FILTERS_BEDS_COUNT,
    SEARCH_RESULTS_FILTERS_BUDGET,
    SEARCH_RESULTS_FILTERS_HOTEL_CHAIN,
    MAP_EXPAND_TOGGLE,
    SEARCH_RESULTS_FILTERS_NEIGHBOURHOODS,
    SEARCH_RESULTS_FILTERS_PARTNERS,
    SEARCH_RESULTS_FILTERS_PROPERTY_TYPE,
    SEARCH_RESULTS_FILTERS_RECOMMENDED,
    SEARCH_RESULTS_FILTERS_REVIEW_SCORE,
    SEARCH_RESULTS_FILTERS_SORT_BY_CHANGE,
    SEARCH_RESULTS_FILTERS_STAR_RATING,
    SEARCH_RESULTS_FILTERS_THINGS_TO_DO,
    SEARCH_RESULTS_GUEST_SELECTOR_CHANGE,
    SEARCH_RESULTS_GUEST_SELECTOR_FOCUS_CHANGE,
    SEARCH_RESULTS_HOVER_LISTING_PROPERTY_CARD,
    SEARCH_RESULTS_INIT_SEARCH_FILTERS,
    SEARCH_RESULTS_MODULE_ACTIVE,
    SEARCH_RESULTS_REQUEST_TYPE,
    SEARCH_RESULTS_RESET_SEARCH_RESULTS_STORE,
    SEARCH_RESULTS_RESOLUTION_TYPE,
    SEARCH_RESULTS_SEARCH_RESULT_CHANGE,
    SEARCH_RESULTS_SEARCH_RESULT_FOCUS_CHANGE,
    SEARCH_RESULTS_SET_BROWSING_HISTORY_SEARCH_DATA,
    SEARCH_RESULTS_SET_FILTERS,
    SEARCH_RESULTS_SET_FILTERS_BAR_COLLAPSED,
    SEARCH_RESULTS_SET_PAGE_NUMBER,
    SEARCH_RESULTS_SET_VIEW_MODE,
    SEARCH_RESULTS_SET_YOUR_BUDGET,
    SEARCH_RESULTS_TOGGLE_MAP_STYLING_BY_POI_TYPE,
    SEARCH_RESULTS_UPDATE_META,
    SEARCH_RESULTS_UPDATE_RATE,
    SEARCH_RESULTS_VIEW_STATE,
    SEARCH_RESULTS_FILTERS_ACTIVITIES,
    SEARCH_RESULTS_SECONDARY_FILTERS_INTERACTED,
    SEARCH_RESULTS_UPDATE_PROPERTY,
    SEARCH_RESULTS_RESET_AVAILABILITY_NEEDED
} from '../types';
import { DestinationTypeEnum } from '@/static/contants';

const getInitialState = (isPresetStorage = false) => {
    let searchTerm = '';
    let locationType;
    let listingId;
    let searchIntend = SearchIntendTypes.SEARCH_BY_THERM;
    let tabId = propertyVariant.HOTEL;
    let dates = { startDate: null, endDate: null };
    let guests = [
        getGuestUnit(guestAgeTypes.ADULT)
    ];
    let rooms = 1;
    let beds = {};
    let bathrooms = {};
    let bedrooms = {};
    let sortBy;
    let amenities = {};
    let starRatings = {};
    let propertyTypes = {};
    let recommended = {};
    let thingsToDo = {};
    let reviewScore = {};
    let budget = [];
    let neighbourhoods = {};
    let hotelChains = {};
    let viewportMapBounds = {};
    let attractions = {};
    let activities = {};
    let searchedLocation = {};

    if (isPresetStorage) {
        const asHook = false;
        const getDates = useDates(asHook);
        const getGuests = useGetGuests(asHook);
        const getBudget = useBudget(asHook);
        const restQueryParams = getSearchQueryParams({
            path: window.location.search,
            params: [
                { key: 'hotels' },
                { key: 'type' },
                { key: 'checkin', defaultValue: null },
                { key: 'checkout', defaultValue: null },
                { key: 'city', defaultValue: '', alias: 'location' },
                { key: 'location_type' },
                { key: 'listing_id' },
                { key: 'guests', defaultValue: 2, alias: 'adults' },
                { key: 'children', defaultValue: 0 },
                { key: 'childrenAges', defaultValue: '' },
                { key: 'children[]', isMultiple: true },
                { key: 'rooms', defaultValue: 1 },
                { key: 'infants', defaultValue: 0 },
                { key: 'sort' },
                // map bounds if present
                { key: 'nwLat' },
                { key: 'nwLng' },
                { key: 'seLat' },
                { key: 'seLng' },
                { key: 'lat' },
                { key: 'lon' },
                { key: 'zoom_level' },
                { key: 'mobile_zoom_level' },
                // property_count
                { key: 'property_count' },
                //
                { key: 'listvalue[]', defaultValue: [], isMultiple: true },
                { key: 'star_rating[]', defaultValue: [], isMultiple: true },
                { key: 'property_type[]', defaultValue: [], isMultiple: true },
                { key: 'things_to_do[]', defaultValue: [], isMultiple: true },
                { key: 'review_score[]', defaultValue: [], isMultiple: true },
                { key: 'popular[]', defaultValue: [], isMultiple: true },
                { key: 'neighbourhood[]', defaultValue: [], isMultiple: true },
                { key: 'attraction[]', defaultValue: [], isMultiple: true },
                { key: 'hotel_chain[]', defaultValue: [], isMultiple: true },
                { key: 'bed[]', defaultValue: [], isMultiple: true },
                { key: 'bedroom[]', defaultValue: [], isMultiple: true },
                { key: 'bathroom[]', defaultValue: [], isMultiple: true },
                { key: 'activity[]', defaultValue: [], isMultiple: true },
            ]
        });

        const {
            hotels,
            type,
            city,
            checkin, checkout,
            rooms: roomCount,
            guests: adultsCount,
            children,
            infants,
            nwLat, nwLng, seLat, seLng, lat, lon,
            sort,
            property_count: propertyCount
        } = restQueryParams;

        const roomCountValidated = guestsValidator([constants.guestTypes.ROOM, roomCount]);
        const adultsCountValidated = guestsValidator([constants.guestTypes.ADULT, adultsCount]);
        const childrenValidated = guestsValidator([constants.guestTypes.CHILD, children]);
        const infantsValidated = guestsValidator([constants.guestTypes.INFANT, infants]);

        searchIntend = getSearchIntent({ nwLat, nwLng, seLat, seLng, lat, lon });

        locationType = getValidLocationType(restQueryParams.location_type, LocationTypes.CITY);

        listingId = getListingId(restQueryParams.listing_id, locationType);

        searchTerm = aggregateLocationByType({ type: locationType, action: normalizeLocation })(city);

        const isVR = type ? (propertyVariant.VR === type) : (hotels === 'false' || typeof hotels === 'undefined');
        tabId = isVR ? propertyVariant.VR : propertyVariant.HOTEL;
        dates = getDates({ checkin, checkout, isVR: false });
        guests = getGuests({
            adults: adultsCountValidated,
            children: getChildAgesFromQueryParams(
                restQueryParams,
                childrenValidated
            ),
            infants: infantsValidated
        }, false);
        rooms = convertToNum(roomCountValidated);
        budget = getBudget();
        sortBy = getSortBy({
            locationType,
            sort
        });

        amenities = convertToHash(restQueryParams['listvalue[]']);
        starRatings = convertToHash(restQueryParams['star_rating[]']);
        propertyTypes = convertToHash(restQueryParams['property_type[]']);
        thingsToDo = convertToHash(restQueryParams['things_to_do[]']);
        reviewScore = convertToHash(restQueryParams['review_score[]']);
        recommended = convertToHash(restQueryParams['popular[]']);
        neighbourhoods = convertToHash(restQueryParams['neighbourhood[]']);
        hotelChains = convertToHash(restQueryParams['hotel_chain[]']);
        attractions = convertToHash(restQueryParams['attraction[]']);
        activities = convertToHash(restQueryParams['activity[]']);
        viewportMapBounds = getCoordinates({
            nwLat, nwLng, seLat, seLng,
            lat, lon
        });

        beds = convertToHash(restQueryParams['bed[]']);
        bathrooms = convertToHash(restQueryParams['bathroom[]']);
        bedrooms = convertToHash(restQueryParams['bedroom[]']);

        searchedLocation = {
            locationType,
            [filterTypes.viewportMapBounds]: viewportMapBounds,
            searchTerm: normalizeLocationString(city),
            zoomLevel: Number(restQueryParams.zoom_level) || undefined,
            mobileZoomLevel: Number(restQueryParams.mobile_zoom_level) || undefined,
            propertyCount
        };
    }

    return {
        areSearchFiltersValid: true,
        isModuleActive: false,

        resolutionType: null,
        viewState: null,

        tabId, // propertyVariant.HOTEL,

        filtersModel: {
            /** common = {}, hotels = {}, vr = {} */
        },

        searchResult: null,
        locationType,
        [filterTypes.searchTerm]: searchTerm, //''
        listingId,

        [filterTypes.dates]: dates,
        // {
            /** startDate = {}, endDate = {} */
        //},

        [filterTypes.guests]: guests,/*[
            getGuestUnit(guestAgeTypes.ADULT)
        ],*/

        [filterTypes.rooms]: rooms, // 1,

        [filterTypes.budget]: budget,
        // [
            /**
             * 10, 1000
             */
        // ],

        [filterTypes.propertyTypes]: propertyTypes, //{},

        [filterTypes.starRatings]: starRatings, //{},

        [filterTypes.amenities]: amenities, // {},

        [filterTypes.sortBy]: sortBy, // undefined, // sortByTypes.RECOMMENDED,

        searchResultsRequestCancellationSource: null,
        searchResultsRequestLoading: true,
        searchResultsRequestError: null,
        searchResultsRequestPayload: [],
        searchResultsPage: 1,

        // triggerAPIWithBounds: null,
        searchIntend, //SearchIntendTypes.SEARCH_BY_THERM,

        hoveredListingProperty: {},

        searchFiltersState: searchFiltersState.UNINITIALIZED,

        amenitiesContainerOffsetHeight: 0,

        isSearchResultFocused: false,
        datePickerFocus: null,
        isGuestSelectorFocused: false,
        isSetYourBudget: true,

        // Multiple Neighborhoods
        [filterTypes.neighbourhoods]: neighbourhoods, // {},

        neighborhoodRequestCancellationSource: null,
        neighborhoodRequestLoading: false,
        neighborhoodRequestError: null,
        neighborhoodRequestPayload: [],

        // Multiple Landmarks
        [filterTypes.landmarks]: {},
        landmarkRequestCancellationSource: null,
        landmarkRequestLoading: false,
        landmarkRequestError: null,
        landmarkRequestPayload: [],

        // Popular filters
        [filterTypes.recommended]: recommended, // {},

        // Leisure
        [filterTypes.things_to_do]: thingsToDo,

        // Review Score
        [filterTypes.review_score]: reviewScore,

        // Beds, Bedrooms, Bathrooms
        [filterTypes.beds]: beds,
        [filterTypes.bedrooms]: bedrooms,
        [filterTypes.noofbathrooms]: bathrooms,

        // If SRP viewed as a host or guest
        viewMode: General.isHostMode() ? viewMode.HOST_VIEW : viewMode.DEFAULT,

        // WebSocket meta
        meta: {},

        // If location type HOTEL | VR
        hotelData: {},

        // Caching layer session expire time
        sessionExpireAt: null,

        requestType: undefined, // RequestType.WEB_SOCKET,

        isFiltersBarCollapsed: true,

        // widget checkbox on SRP map
        boundsSearchEnabled: true,

        // widget on SRP expanded map
        mapStylingByPoiType: StyleTypes.ATTRACTION,

        // partners only staging
        [filterTypes.partners]: {},

        // hotel chain filter
        [filterTypes.hotelChains]: hotelChains,

        // viewport bounds
        [filterTypes.viewportMapBounds]: viewportMapBounds,

        [filterTypes.attractions]: attractions,

        // activities
        [filterTypes.activities]: activities,

        // indicate if user intracted with map
        isMapInteracted: locationType === LocationTypes.VIEWPORT,
        isMapInteractedLoading: false,

        // store artifact from user search
        searchedLocation,

        // Zooming state of map
        zoom_in: undefined,
        zoom_out: undefined,

        // Secondary search filter
        isSecondarySearchFilterInteracted: false,

        isFiltersDataRequestLoading: false
    };
};

export default createReducer(getInitialState(), {
    [SEARCH_RESULTS_MODULE_ACTIVE]: (state, { payload }) => ({ ...state, isModuleActive: payload.isModuleActive }),
    [ARE_SEARCH_FILTERS_VALID]: (state, { payload }) => ({ ...state, areSearchFiltersValid: payload.areSearchFiltersValid }),

    [SEARCH_RESULTS_RESOLUTION_TYPE]: (state, { payload }) => ({ ...state, resolutionType: payload.resolutionType }),
    [SEARCH_RESULTS_VIEW_STATE]: (state, { payload }) => ({
        ...state,
        viewState: payload.viewState
    }),

    [SEARCH_RESULTS_INIT_SEARCH_FILTERS]: (state, { payload }) => ({
        ...state,
        searchResult: payload.searchResult,
        [filterTypes.searchTerm]: splitByToken(concatFilter(payload[filterTypes.searchTerm])).join(', '),
        locationType: getValidLocationType(
            payload.locationType,
            state.locationType
        )
    }),

    [SEARCH_RESULTS_CHANGE_SEARCH_INTEND]: (state, { payload }) => ({ ...state, searchIntend: payload.searchIntend }),

    [SEARCH_RESULTS_SEARCH_RESULT_CHANGE]: (state, { payload }) => {
        const locationType = getValidLocationType(
            payload.value.locationType,
            state.locationType
        );

        return {
            ...state,
            locationType,
            ...getPayloadBasedOnExistedKeys([
                'isMapInteracted',
                'searchResult',
                filterTypes.searchTerm,
                'listingId',
                filterTypes.viewportMapBounds,
                'searchIntend',
                'searchedLocation',
                'propertyCount'
            ], payload.value),
            isMapInteractedLoading: Boolean(payload.value.isMapInteracted),
            zoom_in: payload.value.zoom_in,
            zoom_out: payload.value.zoom_out,
            [filterTypes.sortBy]: getSortBy({
                locationType
            }),
            meta: {
                ...state.meta,
                total_properties: 0
            },
            searchResultsPage: 1
        };
    },

    [SEARCH_RESULTS_DATEPICKER_CHANGE]: (state, { payload }) => ({
        ...state,
        [filterTypes.dates]: payload.value
    }),

    [SEARCH_RESULTS_GUEST_SELECTOR_CHANGE]: (state, { payload }) => ({
        ...state,
        [filterTypes.guests]: payload.value[filterTypes.guests],
        [filterTypes.rooms]: payload.value[filterTypes.rooms]
    }),

    [SEARCH_RESULTS_FILTERS_BUDGET]: (state, { payload }) => ({
        ...state,
        [filterTypes.budget]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_SORT_BY_CHANGE]: (state, { payload }) => ({
        ...state,
        [filterTypes.sortBy]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_PROPERTY_TYPE]: (state, { payload }) => ({
        ...state,
        [filterTypes.propertyTypes]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_STAR_RATING]: (state, { payload }) => ({
        ...state,
        [filterTypes.starRatings]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_AMENITIES]: (state, { payload }) => ({
        ...state,
        [filterTypes.amenities]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_RECOMMENDED]: (state, { payload }) => ({
        ...state,
        [filterTypes.recommended]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_THINGS_TO_DO]: (state, { payload }) => ({
        ...state,
        [filterTypes.things_to_do]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_ACTIVITIES]: (state, { payload }) => ({
        ...state,
        [filterTypes.activities]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_REVIEW_SCORE]: (state, { payload }) => ({
        ...state,
        [filterTypes.review_score]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_NEIGHBOURHOODS]: (state, { payload }) => ({
        ...state,
        [filterTypes.neighbourhoods]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_ATTRACTIONS]: (state, { payload }) => ({
        ...state,
        [filterTypes.attractions]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_PARTNERS]: (state, { payload }) => ({
        ...state,
        [filterTypes.partners]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_HOTEL_CHAIN]: (state, { payload }) => ({
        ...state,
        [filterTypes.hotelChains]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_BEDROOMS_COUNT]: (state, { payload }) => ({
        ...state,
        [filterTypes.bedrooms]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_BATHROOMS_COUNT]: (state, { payload }) => ({
        ...state,
        [filterTypes.noofbathrooms]: payload.value
    }),
    [SEARCH_RESULTS_FILTERS_BEDS_COUNT]: (state, { payload }) => ({
        ...state,
        [filterTypes.beds]: payload.value
    }),

    [SEARCH_RESULTS_SECONDARY_FILTERS_INTERACTED]: (state, { payload }) => ({
        ...state,
        isSecondarySearchFilterInteracted: payload.isSecondarySearchFilterInteracted
    }),

    [SEARCH_RESULTS_SET_PAGE_NUMBER]: (state, { payload }) => ({ ...state, searchResultsPage: payload.searchResultsPage }),

    [SEARCH_RESULTS_RESET_SEARCH_RESULTS_STORE]: (state, { payload }) => ({ ...state, ...getInitialState(payload.isPresetStorage) }),

    [SEARCH_RESULTS_HOVER_LISTING_PROPERTY_CARD]: (state, { payload }) => ({ ...state, hoveredListingProperty: payload.hoveredListingProperty }),

    [SEARCH_RESULTS_CHANGE_SEARCH_FILTERS_STATE]: (state, { payload }) => ({ ...state, searchFiltersState: payload.searchFiltersState }),

    [SEARCH_RESULTS_AMENITIES_CONTAINER_OFFSET_HEIGHT_CHANGE]: (state, { payload }) => ({ ...state, amenitiesContainerOffsetHeight: payload.amenitiesContainerOffsetHeight }),

    [SEARCH_RESULTS_SEARCH_RESULT_FOCUS_CHANGE]: (state, { payload }) => ({ ...state, isSearchResultFocused: payload.isSearchResultFocused }),
    [SEARCH_RESULTS_DATEPICKER_FOCUS_CHANGE]: (state, { payload }) => ({ ...state, datePickerFocus: payload.datePickerFocus }),
    [SEARCH_RESULTS_GUEST_SELECTOR_FOCUS_CHANGE]: (state, { payload }) => ({ ...state, isGuestSelectorFocused: payload.isGuestSelectorFocused }),

    [SEARCH_RESULTS_SET_YOUR_BUDGET]: (state, { payload }) => ({ ...state, isSetYourBudget: payload.isSetYourBudget }),

    [SEARCH_RESULTS_SET_BROWSING_HISTORY_SEARCH_DATA]: (state, { payload }) => ({
        ...state,
        tabId: payload.tabId,
        [filterTypes.dates]: payload[filterTypes.dates],
        [filterTypes.rooms]: payload[filterTypes.rooms],
        [filterTypes.guests]: payload[filterTypes.guests]
    }),

    // BE FILTERS IMPLEMENTATION
    /**
     * @param {*} payload {
     *  payload: {
     *      meta, data, filters
     *  }
     * }
     */
    [SEARCH_RESULTS_DATA_AVAILABLE]: (state, { payload }) => ({
        ...state,
        ...payload
    }),

    [SEARCH_RESULTS_SET_FILTERS]: (state, { payload }) => ({
        ...state,
        [filterTypes.budget]: payload[filterTypes.budget],
        [filterTypes.propertyTypes]: payload[filterTypes.propertyTypes],
        [filterTypes.starRatings]: payload[filterTypes.starRatings],
        [filterTypes.amenities]: payload[filterTypes.amenities],
        [filterTypes.things_to_do]: payload[filterTypes.things_to_do],
        [filterTypes.activities]: payload[filterTypes.activities],
        [filterTypes.review_score]: payload[filterTypes.review_score],
        [filterTypes.neighbourhoods]: payload[filterTypes.neighbourhoods],
        [filterTypes.attractions]: payload[filterTypes.attractions],
        [filterTypes.recommended]: payload[filterTypes.recommended],
        [filterTypes.hotelChains]: payload[filterTypes.hotelChains],
        [filterTypes.noofbathrooms]: payload[filterTypes.noofbathrooms],
        [filterTypes.beds]: payload[filterTypes.beds],
        [filterTypes.bedrooms]: payload[filterTypes.bedrooms]
    }),
    [SEARCH_RESULTS_DATA_LOADING]: (state, { payload }) => ({
        ...state,
        searchResultsRequestLoading: payload.searchResultsRequestLoading,
        isMapInteractedLoading: false,
        searchResultsRequestCancellationSource: payload.searchResultsRequestCancellationSource ?? null
    }),

    [SEARCH_RESULTS_FILTERS_REQUEST_DATA_LOADING]: (state, { payload }) => ({
        ...state,
        isFiltersDataRequestLoading: payload.isFiltersDataRequestLoading
    }),

    [SEARCH_RESULTS_DATA_ERROR]: (state, { payload }) => ({
        ...state,
        searchResultsRequestError: payload.searchResultsRequestError
    }),

    /*[SEARCH_RESULTS_UPDATE_RATE]: (state, { payload }) => {
        let currentRate;

        const currencyMapper = property => {
            if ((Object.entries(property).length <= 0) || !property.price) {
                return property;
            }

            const amount = priceCurrencyConverter(property.price.amount, property.price.rate, payload.rate);

            const originalPrice = property.price.original_price
                ? priceCurrencyConverter(property.price.original_price, property.price.rate, payload.rate)
                : null; // calculateFakeDiscount(amount, [16, 24]);

            if (typeof currentRate === 'undefined') {
                currentRate = property.price.rate;
            }

            return {
                ...property,
                price: {
                    ...property.price,
                    amount,
                    rate: payload.rate,
                    currency_symbol: payload.symbol,
                    currency: payload.short,
                    original_price: originalPrice
                }
            };
        };

        currentRate ||= payload.currentRate;

        return {
            ...state,
            filtersModel: {
                ...state.filtersModel,
                price_slots: state.filtersModel.price_slots?.map(slot => ({
                    budget_lower_bound: priceCurrencyConverter(slot.budget_lower_bound, currentRate, payload.rate),
                    budget_higher_bound: priceCurrencyConverter(slot.budget_higher_bound, currentRate, payload.rate)
                })),
                pricemin: priceCurrencyConverter(state.filtersModel.pricemin, currentRate, payload.rate) || DefaultPriceRanges.MIN,
                pricemax: priceCurrencyConverter(state.filtersModel.pricemax, currentRate, payload.rate) || DefaultPriceRanges.MAX
            },
            [filterTypes.budget]: state.budget.map(val =>
                priceCurrencyConverter(val, currentRate, payload.rate)
            ),
            // hotelData: currencyMapper(state.hotelData),
            // searchResultsRequestPayload: state.searchResultsRequestPayload.map(currencyMapper),
            // outside10Km: state.outside10Km.map(currencyMapper)

        };
    },*/

    [SEARCH_RESULTS_SET_VIEW_MODE]: (state, { payload }) => ({
        ...state,
        viewMode: payload.viewMode
    }),

    [SEARCH_RESULTS_UPDATE_META]: (state, { payload }) => ({
        ...state,
        meta: payload.meta
    }),

    [SEARCH_RESULTS_RESET_AVAILABILITY_NEEDED]: state => {
        delete state.meta.is_socket_availability_needed;

        return {
            ...state,
            meta: {
                ...state.meta,
            }
        }
    },

    [SEARCH_RESULTS_REQUEST_TYPE]: (state, { payload }) => ({
        ...state,
        requestType: payload.requestType
    }),

    [SEARCH_RESULTS_SET_FILTERS_BAR_COLLAPSED]: (state, { payload }) => ({
        ...state,
        isFiltersBarCollapsed: payload.isFiltersBarCollapsed
    }),

    [SEARCH_RESULTS_BOUNDS_SEARCH_TOGGLE]: (state, { payload }) => ({
        ...state,
        boundsSearchEnabled: payload.boundsSearchEnabled ?? !state.boundsSearchEnabled
    }),

    [MAP_EXPAND_TOGGLE]: (state, { payload }) => ({
        ...state,
        expandMapEnabled: payload.expandMapEnabled ?? !state.expandMapEnabled
    }),

    [SEARCH_RESULTS_TOGGLE_MAP_STYLING_BY_POI_TYPE]: (state, { payload }) => {
        const { mapStylingByPoiType } = payload;

        return {
            ...state,
            mapStylingByPoiType:
                (mapStylingByPoiType === state.mapStylingByPoiType)
                    ? undefined
                    : mapStylingByPoiType
        };
    },

    [SEARCH_RESULTS_UPDATE_PROPERTY]: (state, {
        payload: {
            listing,
            field: [key, value = true]
        }
    }) => {
        const {
            searchResultsRequestPayload,
            outside10Km,
            mapData,
            hotelData
        } = state;

        const handleUpdate = property => {
            if (compareIds(listing.id, property.product_id)) {
                return {
                    ...property,
                    [key]: value
                };
            }

            return property;
        };

        return {
            ...state,
            hotelData: handleUpdate(hotelData),
            mapData: mapData
                .map(handleUpdate),
            outside10Km: outside10Km
                .map(handleUpdate),
            searchResultsRequestPayload: searchResultsRequestPayload
                .map(handleUpdate)
        };
    }
});

function getPayloadBasedOnExistedKeys(keysArray, payloadValue) {
    const outputPayload = {};

    for (const key of keysArray) {
        if (key in payloadValue) {
            outputPayload[key] = payloadValue[key];
        }
    }

    return outputPayload;
}

function getCoordinates({
    nwLat, nwLng, seLat, seLng,
    lat, lon
}) {
    const baseCoords = getValidated({
        inputValue: { lat, lon }
    });

    const viewportCoords = General.validateCoords({
        nwLat, nwLng, seLat, seLng,
        lat, lon
    });

    if (viewportCoords) {
        return {
            ...baseCoords,
            ...viewportCoords
        };
    }

    return baseCoords;
}

function getChildAgesFromQueryParams(restQueryParams, childrenValidated) {
    let childAgesCollection = [];

    if (restQueryParams['children[]'].length > 0) {
        childAgesCollection = restQueryParams['children[]'];
    }else if (restQueryParams[filterTypes.childrenAges].split(',').length > 0) {
        childAgesCollection = restQueryParams[filterTypes.childrenAges].split(',');
    }

    return childAgesCollection
        .filter(age => age.trim())
        .join(',') || childrenValidated;
}

function getSortBy({ locationType, sort }) {
    return ({
        [DestinationTypeEnum.POI]: sortByTypes.CLOSEST_TO_CENTER,
        [DestinationTypeEnum.AROUND]: sortByTypes.CLOSEST_TO_CENTER,
        [DestinationTypeEnum.AIRPORT]: sortByTypes.CLOSEST_TO_CENTER
    }[locationType]) ?? (sort || sortByTypes.RECOMMENDED);
}
