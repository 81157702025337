import { DateRangeModel } from '../../DateRange';
import { GuestModel } from '../../GuestCount';
import { DestinationModel } from '../../Destination';

export const OmnisearchStateFactory = {
    Destination: () => new DestinationModel,

    DateRange: () => new DateRangeModel,

    Guest: () => new GuestModel,

    Interests: () => []
};
