import { createContext, memo } from 'react';

import useOmnisearch from './useOmnisearch';
import AttractionsNearby from '../AttractionsNearby';
import Interests from '../Interests';

export const OmnisearchStateContext = createContext({});

const OmnisearchState = ({ children }) => (
    <OmnisearchStateContext.Provider
        value={useOmnisearch()}
    >
        <AttractionsNearby>
            <Interests>
                {children}
            </Interests>
        </AttractionsNearby>
    </OmnisearchStateContext.Provider>
);

export default memo(OmnisearchState);
